/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'music-player': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M4 3a1 1 0 011-1h6a1 1 0 011 1v3a1 1 0 01-1 1H5a1 1 0 01-1-1zm1 0v3h6V3zm3 9a1 1 0 100-2 1 1 0 000 2"/><path pid="1" d="M11 11a3 3 0 11-6 0 3 3 0 016 0m-3 2a2 2 0 100-4 2 2 0 000 4"/><path pid="2" d="M2 2a2 2 0 012-2h8a2 2 0 012 2v12a2 2 0 01-2 2H4a2 2 0 01-2-2zm2-1a1 1 0 00-1 1v12a1 1 0 001 1h8a1 1 0 001-1V2a1 1 0 00-1-1z"/>',
    },
});
